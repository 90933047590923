import * as Sentry from '@sentry/react';
import type { UseMutationResult } from '@tanstack/react-query';
import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { PublicApiTypes } from '@tf/api';
import { PublicApiClient } from '@tf/api';
import { TFNotifier } from '@tf/ui';

const tokensQueryOptions = () => {
	return queryOptions({
		queryKey: ['api-tokens'],
		queryFn: async (): Promise<PublicApiTypes.TokenControllerListTokensData> => {
			const api = PublicApiClient.getInstance();
			const res = await api.tokenControllerListTokens();
			return res.json();
		},
		staleTime: 20 * 1000,
	});
};

export const useTokens = () => {
	return useQuery(tokensQueryOptions());
};

export const useCreateTokenMutation: () => UseMutationResult<
	PublicApiTypes.TokenResponseDto,
	Error,
	PublicApiTypes.CreateTokenDto
> = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (
			data: PublicApiTypes.CreateTokenDto
		): Promise<PublicApiTypes.TokenResponseDto> => {
			const api = PublicApiClient.getInstance();
			const res = await api.tokenControllerCreateToken(data);
			return res.json();
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries(tokensQueryOptions());
		},
		onError: () => {
			TFNotifier.error('Failed to create API token. Please try again');
		},
	});
};

export const useDeleteTokenMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ id }: { id: string }) => {
			const api = PublicApiClient.getInstance();
			const res = await api.tokenControllerDeleteToken(id);
			return res.json();
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries(tokensQueryOptions());
			TFNotifier.success('API token deleted successfully');
		},
		onError: (err) => {
			const message = "Couldn't delete API token";
			Sentry.captureException(new Error(message, { cause: err }));
			TFNotifier.error('Failed to delete API token. Please try again');
		},
	});
};
