import { Navigate } from 'react-router-dom';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { TFLoadingOverlay } from '@tf/ui';
import { T } from '@tf/utils';

import { useTokens } from '@/core/api/tokens';
import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { TokensTable } from '@/components/settings/TokensTable';
import { AddTokenButton } from '@/components/settings/TokensTable/AddTokenButton';

export const TokenListRoute = () => {
	const { isLoading } = useTokens();

	const canManageApiTokens = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_API_TOKENS);

	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	if (!canManageApiTokens) {
		return <Navigate to="/" replace />;
	}

	if (isLoading) return <TFLoadingOverlay />;

	return (
		<>
			<TFPageHeader tenant={tenant} title="API Tokens" toolbar={<AddTokenButton />} />
			<TFPageContent>
				<TokensTable />
			</TFPageContent>
		</>
	);
};
