import type { PublicApiTypes } from '@tf/api';
import { Button, TFDataTable, TFLoadingOverlay, TFText } from '@tf/ui';

import { useDeleteTokenMutation, useTokens } from '@/core/api/tokens';

type CellProps = {
	row: {
		original: PublicApiTypes.TokenControllerListTokensData;
	};
};

export const TokensTable = () => {
	const { isPending, data: tokens, isError } = useTokens();

	const deleteTokenMutation = useDeleteTokenMutation();

	if (isPending) {
		return <TFLoadingOverlay h={300} label="Tokens loading..." />;
	}

	if (isError) {
		return <div>Error, please try again</div>;
	}

	return (
		<TFDataTable<PublicApiTypes.TokenControllerListTokensData>
			name="tokens-table"
			data={tokens ?? []}
			defs={[
				{
					header: 'Name',
					accessorKey: 'name',
					size: 150,
					sortingFn: (rowA, rowB) => {
						const rowAName = rowA.original.name;
						const rowBName = rowB.original.name;
						return rowAName.localeCompare(rowBName);
					},
					Cell: ({ row }: CellProps) => {
						const { name } = row.original;

						return (
							<TFText py=".25rem" lineClamp={1} inherit>
								{name}
							</TFText>
						);
					},
				},
				{
					header: 'Actions',
					accessorKey: 'actions',
					size: 100,
					enableSorting: false,
					enableResizing: false,
					Cell: ({ row }: CellProps) => {
						const { id } = row.original;
						return (
							<Button
								variant="light"
								color="red"
								disabled={deleteTokenMutation.isPending && deleteTokenMutation.variables?.id === id}
								onClick={async () => {
									await deleteTokenMutation.mutateAsync({ id });
								}}
								size={'xs'}
							>
								Revoke
							</Button>
						);
					},
				},
			]}
		/>
	);
};
