import { useConfigContext } from './use-config';

export const useDisabledAutocompleteAttributes = () => {
	const { disableAutocomplete } = useConfigContext((s) => s);

	if (!disableAutocomplete) return;

	return {
		'data-1p-ignore': true,
		'data-lpignore': true,
		autoComplete: 'off',
	};
};
