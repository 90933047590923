import React, { useEffect } from 'react';

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, useFormContext } from 'react-hook-form';

import type { BackendTypes } from '@tf/api';
import { Input, MultiSelect } from '@tf/ui';

import { useConfigContext, useDisabledAutocompleteAttributes } from '../../../hooks';
import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

interface Props extends DefaultInputProps {
	enumName?: string;
	isSearchable?: boolean;
	options?: BackendTypes.SelectOption[];
}

export function MultiSelectInput({
	name,
	label,
	description,
	enumName,
	placeholder,
	isReadOnly,
	isSearchable,
	isRequired,
	className,
	tooltip,
	...props
}: Props) {
	const { _ } = useLingui();
	const form = useFormContext();
	const getEnumOptions = useConfigContext((s) => s.getEnumOptions);
	const options = enumName ? getEnumOptions(enumName) : props.options || [];
	const disabledAutocompleteAttributes = useDisabledAutocompleteAttributes();

	useEffect(() => {
		const value = form.getValues(name);
		form.setValue(name, value || undefined);
	}, []);

	return (
		<Controller
			control={form.control}
			name={name}
			render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
				<Input.Wrapper id={name} className={className} label={label} required={isRequired}>
					<MultiSelect
						id={name}
						ref={ref}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						error={Boolean(error)}
						placeholder={
							value?.length
								? ''
								: isReadOnly
								? _(
										msg({
											id: 'fb.multiselectInput.empty',
											message: 'Not specified',
										})
								  )
								: placeholder ||
								  _(
										msg({
											id: 'fb.multiselectInput.placeholder',
											message: 'Select options...',
										})
								  )
						}
						description={description}
						data={options.map(({ value, label }) => ({ value, label }))}
						readOnly={isReadOnly}
						data-readonly={isReadOnly}
						rightSectionPointerEvents="initial"
						rightSection={
							tooltip || error ? (
								<TooltipIcon tooltip={tooltip} error={error} />
							) : isReadOnly ? (
								<div />
							) : undefined
						}
						searchable={isSearchable || options.length > 5}
						{...disabledAutocompleteAttributes}
					/>
				</Input.Wrapper>
			)}
		/>
	);
}
