import React from 'react';

import type {
	ComboboxItem,
	ComboboxLikeRenderOptionInput,
	CSSVariablesResolver,
	DefaultMantineColor,
} from '@mantine/core';
import { Box, MantineProvider } from '@mantine/core';
import type { EmotionStyles } from '@mantine/emotion';
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';

export interface ThemeProviderProps {
	children: React.ReactNode;
}

type Colors = Partial<
	Record<
		DefaultMantineColor,
		[string, string, string, string, string, string, string, string, string, string]
	>
>;

const fontFamily = 'Inter, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif';

const colors: Colors = {
	gray: [
		'#F9FAFB',
		'#F3F4F6',
		'#E5E7EB',
		'#D1D5DB',
		'#9CA3AF',
		'#6B7280',
		'#4B5563',
		'#374151',
		'#1F2937',
		'#111827',
	],
	brand: [
		'#EFF6FF',
		'#DBEAFE',
		'#BFDBFE',
		'#93C5FD',
		'#60A5FA',
		'#3B82F6',
		'#2563EB',
		'#1D4ED8',
		'#1E40AF',
		'#1E3A8A',
	],
	red: [
		'#FEF2F2',
		'#FEE2E2',
		'#FECACA',
		'#FCA5A5',
		'#F87171',
		'#EF4444',
		'#DC2626',
		'#B91C1C',
		'#991B1B',
		'#7F1D1D',
	],
	slate: [
		'#F8FAFC',
		'#F1F5F9',
		'#E2E8F0',
		'#CBD5E1',
		'#94A3B8',
		'#64748B',
		'#475569',
		'#334155',
		'#1E293B',
		'#0F172A',
	],
};

interface ThemeComponent {
	classNames?: any;
	styles?: EmotionStyles;
	vars?: any;
	defaultProps?: any;
}

export type ThemeComponents = Record<string, ThemeComponent>;

const themeComponents: Record<string, ThemeComponent> = {
	Alert: {
		styles(theme, params) {
			return {
				root: {
					padding: '.5rem 1rem',
					borderWidth: 1,
					borderStyle: 'solid',
					...(params.color && {
						borderColor: theme.colors[params.color][3],
					}),
				},
				message: { fontSize: '0.825rem' },
				icon: { marginRight: 6 },
			};
		},
	},
	AppShell: {
		defaultProps: {
			padding: 0,
		},
		styles: ({ colors }) => ({
			root: {
				backgroundColor: colors.gray[0],
			},
		}),
	},
	Breadcrumbs: {
		defaultProps: {
			separator: '/',
		},
		styles: {
			breadcrumb: { fontSize: 12 },
			separator: { margin: '0 .375rem' },
		},
	},
	Button: {
		defaultProps: {
			size: 'md',
			variant: 'gradient',
			gradient: { from: 'brand', to: 'blue' },
			loaderProps: { size: 14 },
		},
		styles: (theme, params) => {
			return {
				root: {
					transition: 'all .15s ease-in-out',
					height: '2.5rem',

					...(params.size === 'md' && {
						height: '2.25rem',
						fontSize: '.85rem',
					}),
					...(params.size === 'sm' && {
						height: '2rem',
						fontSize: '.8rem',
					}),
					...(params.size === 'xs' && {
						height: '1.8rem',
						fontSize: '.8rem',
					}),
				},
				leftIcon: {
					...(params.size === 'md' && {
						marginLeft: '.375rem',
						marginRight: '.5rem',
					}),
					...(params.size === 'sm' && {
						marginLeft: '.225rem',
						marginRight: '.5rem',
					}),
					...(params.size === 'xs' && {
						marginLeft: '.375rem',
						marginRight: '.5rem',
					}),
				},
			};
		},
	},
	Checkbox: {
		styles: {
			label: {
				paddingLeft: '0.5rem',
			},
		},
	},
	Modal: {
		defaultProps: {
			radius: 'sm',
		},
		styles: ({ colors }) => ({
			header: {
				display: 'flex',
				padding: '.75rem 1rem',
				margin: 0,
				borderWidth: '0 0 1px',
				borderStyle: 'solid',
				borderColor: colors.gray[2],
			},
			title: {
				fontSize: '1.2rem',
				fontWeight: 500,
			},
			body: { padding: 0 },
		}),
	},
	InputWrapper: {
		styles: {
			label: {
				fontSize: 14,
				marginBottom: '.25rem',
				cursor: 'pointer',
			},
		},
	},
	Loader: { defaultProps: { color: 'brand', type: 'bars' } },
	Input: {
		defaultProps: {
			variant: 'filled',
			autoComplete: 'off',
		},
		styles: ({ colors }) => ({
			input: {
				transition: 'border-color .15s ease',
				borderWidth: 1,
				borderStyle: 'solid',
				borderRadius: 3,
				borderColor: colors.gray[3],

				'&:hover': {
					borderColor: colors.brand[3],
				},
				'&:focus': {
					outlineStyle: 'solid',
					outlineWidth: 0.75,
					outlineColor: colors.brand[2],
				},
				'&[data-error="true"]': {
					borderColor: '#DC2626',
					'&:focus': {
						outlineStyle: 'solid',
						outlineWidth: 0.75,
						outlineColor: `#DC2626 !important`,
						borderColor: '#DC2626',
					},
				},
				'&[data-readonly="true"]': {
					cursor: 'default',

					'&:hover': {
						borderColor: colors.gray[3],
					},
					'&:focus': {
						borderColor: colors.gray[3],
						outlineColor: 'transparent',
					},
					'&:active': {
						borderColor: colors.gray[3],
					},
				},
			},
			invalid: {
				color: 'inherit',
				'::placeholder': {
					color: '#5d5f67',
				},
			},
		}),
	},
	MultiSelect: {
		defaultProps: {
			withCheckIcon: false,
		},
		styles: ({ colors }) => ({
			option: {
				padding: '9px 12px',
			},
			input: {
				'&[data-readonly="true"]': {
					userSelect: 'all',
					cursor: 'default',
					'&:hover': {
						borderColor: 'red !important',
					},
					'&:focus': {
						borderColor: colors.gray[3],
						outlineColor: 'transparent',
					},
					'&:active': {
						borderColor: colors.gray[3],
					},
				},
			},
		}),
	},
	Select: {
		defaultProps: {
			maxDropdownHeight: 280,
			allowDeselect: false,
			withCheckIcon: false,
			styles: {
				option: {
					padding: 0,
				},
			},
			renderOption: (item: ComboboxLikeRenderOptionInput<ComboboxItem>) => (
				<Box
					p="8px 11px"
					w="100%"
					sx={({ colors }) => {
						if (item.checked) {
							return { backgroundColor: colors.brand[6], borderRadius: '4px', color: colors.gray[0] };
						}
						return {};
					}}
				>
					{item.option.label}
				</Box>
			),
			comboboxProps: {
				withinPortal: true,
			},
			placeholder: 'Select option...',
			'data-readonly': false,
		},
		styles: ({ colors }) => ({
			input: {
				'&:focus': {
					outlineStyle: 'solid',
					outlineWidth: 0.75,
					outlineColor: colors.brand[2],
				},

				'&[data-readonly="false"]': {
					backgroundColor: colors.gray[1],
					borderRadius: 3,
					borderWidth: 1,
					borderColor: colors.gray[3],
					'&:hover': {
						borderColor: colors.brand[3],
					},
					'&:focus': {
						borderColor: colors.brand[5],
						outlineStyle: 'solid',
						outlineWidth: 0.75,
						outlineColor: colors.brand[2],
					},
				},

				'&[data-error="true"]': {
					borderColor: `#DC2626 !important`,
					backgroundColor: colors.gray[1],
					color: '#000',
					'&::placeholder': {
						color: '#000',
					},
					'&:focus': {
						outlineStyle: 'solid',
						outlineWidth: 0.75,
						outlineColor: `#DC2626 !important`,
						borderColor: '#DC2626',
					},
				},
			},
			item: {
				fontSize: '.8rem',
				padding: '0.5rem 1rem',
			},
			dropdown: {
				zIndex: 'auto',
			},
		}),
	},

	Tabs: {
		styles: {
			root: { backgroundColor: 'transparent' },
			tab: { transition: 'border-color .15s ease-in-out' },
			labelActive: { color: `${colors.brand?.[6]} !important` },
		},
	},

	DateInput: {
		styles: ({ colors }) => ({
			input: {
				'&[data-read-only="true"]': {
					userSelect: 'all',
					cursor: 'default',

					'&:hover': {
						borderColor: colors.gray[3],
					},
					'&:focus': {
						borderColor: colors.gray[3],
						outlineColor: 'transparent',
					},
					'&:active': {
						borderColor: colors.gray[3],
					},
				},
			},
		}),
	},

	Menu: {
		defaultProps: {
			shadow: 'sm',
			withArrow: true,
		},
	},

	Tooltip: {
		defaultProps: {
			withArrow: true,
			size: 'xs',
			openDelay: 500,
		},
		styles: {
			tooltip: {
				fontSize: 12,
			},
		},
	},
	Badge: {
		styles: ({ colors }) => ({
			root: {
				backgroundColor: colors.brand[0],
				color: colors.brand[6],
				fontWeight: 600,
			},
		}),
	},
};

const resolver: CSSVariablesResolver = ({ colors }) => ({
	variables: {
		'--mantine-color-brand-light': colors.brand[0],
		'--mantine-color-error': '#B91C1C',
	},
	light: {
		'--mantine-color-brand-light': colors.brand[0],
		'--mantine-color-error': '#B91C1C',
	},
	dark: {
		'--mantine-color-brand-light': colors.brand[0],
		'--mantine-color-error': '#B91C1C',
	},
});

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
	return (
		<MantineProvider
			stylesTransform={emotionTransform}
			cssVariablesResolver={resolver}
			theme={{
				cursorType: 'pointer',
				focusRing: 'auto',
				respectReducedMotion: true,

				colors,
				primaryColor: 'brand',
				primaryShade: { light: 6, dark: 7 },

				fontFamily,
				fontFamilyMonospace: 'Monaco, Courier, monospace',

				fontSizes: {
					xs: '.75rem',
					sm: '.825rem',
					md: '.9rem',
					lg: '1.2rem',
					xl: '2rem',

					font_xs: '12px',
					font_sm: '14px',
					font_md: '16px',
					font_lg: '18px',
					font_xl: '24px',
					font_xxl: '32px',
				},
				lineHeights: {
					height_xs: '18px',
					height_sm: '20px',
					height_md: '22px',
					height_lg: '24px',
					height_xl: '32px',
					height_xxl: '40px',
				},
				spacing: {
					xs: '.75rem',
					sm: '.85rem',
					md: '1rem',
					lg: '2rem',
					xl: '3rem',

					space_2: '2px',
					space_4: '4px',
					space_8: '8px',
					space_12: '12px',
					space_16: '16px',
					space_20: '20px',
					space_24: '24px',
					space_32: '32px',
					space_44: '44px',
					space_72: '72px',
				},
				radius: {
					round_4: '4px',
					round_8: '8px',
					round_12: '12px',
					round_16: '16px',
					round_20: '20px',
					round_999: '999px',
				},
				headings: {
					fontFamily,
					fontWeight: '500',
					sizes: {
						h1: {
							fontSize: '32px',
							fontWeight: '600',
							lineHeight: '40px',
						},
						h2: {
							fontSize: '24px',
							fontWeight: '600',
							lineHeight: '32px',
						},
						h3: {
							fontSize: '18px',
							fontWeight: '600',
							lineHeight: '20px',
						},
						h4: {
							fontSize: '16px',
							fontWeight: '600',
							lineHeight: '18px',
						},
					},
				},

				defaultRadius: 4,

				other: {
					floatingedges: {
						flexDirection: 'column',
						display: 'flex',
						flexGrow: 1,
						height: '100%',
					},
					'.floatingedges .react-flow__handle': {
						opacity: 0,
					},
				},
				components: themeComponents,
			}}
		>
			<MantineEmotionProvider>{children}</MantineEmotionProvider>
		</MantineProvider>
	);
};
