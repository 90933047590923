import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { Box, Input, TextInput } from '@tf/ui';

import { useDisabledAutocompleteAttributes } from '../../../hooks';
import { CURRENCY_SYMBOLS } from '../../../utils';
import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

function parseNumberFromString(input: string) {
	const str = input.replace(/[^\d,.]/g, '');
	const parsedNumber = Number(str.replace(/,/g, ''));
	if (isNaN(parsedNumber)) {
		return undefined;
	}
	return parsedNumber;
}

interface NumberInputProps extends DefaultInputProps {
	min?: number;
	max?: number;
	placeholder?: string;
	currency?: string;
}

export default function NumberInput({
	name,
	label,
	description,
	placeholder,
	isRequired,
	isReadOnly,
	min,
	max,
	tooltip,
	currency,
}: NumberInputProps) {
	const form = useFormContext();

	useEffect(() => {
		const value = form.getValues(name);
		form.setValue(name, value);
	}, []);

	const disabledAutocompleteAttributes = useDisabledAutocompleteAttributes();

	return (
		<Controller
			control={form.control}
			name={name}
			render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
				return (
					<Input.Wrapper id={name} label={label} labelProps={{ required: isRequired }}>
						<NumericFormat
							id={name}
							value={value}
							onValueChange={(values) => {
								const { floatValue } = values;
								onChange(floatValue ?? '');
							}}
							onBlur={(event) => {
								onBlur();
								if (!min || !max) return;

								const value = parseNumberFromString(event.target.value);
								if (!value) {
									onChange(undefined);
									return;
								}
								if (value < min) {
									onChange(min);
								} else if (value > max) {
									onChange(max);
								}
							}}
							placeholder={placeholder || 'Enter amount'}
							readOnly={isReadOnly}
							thousandSeparator=","
							customInput={TextInput}
							rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
							leftSection={
								currency ? <Box m="0 4px 0 10px">{CURRENCY_SYMBOLS[currency]}</Box> : undefined
							}
							leftSectionWidth="26"
							inputMode="numeric"
							description={description}
							error={Boolean(error)}
							getInputRef={ref}
							{...disabledAutocompleteAttributes}
						/>
					</Input.Wrapper>
				);
			}}
		/>
	);
}
