import {
	ActionIcon,
	Affix as UIAffix,
	createStyles,
	Icon,
	Menu,
	Stack,
	Tooltip,
	Transition,
	useDisclosure,
	useHotkeys,
	useMantineTheme,
	useWindowScroll,
} from '@tf/ui';

import { HaveIssuesModal } from './HaveIssuesModal';

const useStyles = createStyles(({ radius, colors, shadows, spacing }) => ({
	elevation: {
		boxShadow: shadows.md,
	},
	menuDropdown: {
		backgroundColor: colors.gray[9],
		padding: '0.5rem',
		borderColor: 'unset',
	},
	menuItem: {
		fontWeight: 500,
		fontSize: '0.875rem',
		lineHeight: '1.3',
		color: colors.slate[1],
		borderRadius: radius.round_8,
		padding: '0.5rem 0.75rem',
		transition: 'background-color .3s ease-out',
		'&:hover': {
			backgroundColor: colors.slate[8],
		},
		'&[data-hovered]': {
			backgroundColor: colors.slate[8],
			outlineWidth: '1px',
		},
	},
	menuItemSection: {
		marginInlineEnd: spacing.space_8,
	},
	menuItemCaption: {
		fontSize: '0.75rem',
		color: colors.gray[4],
	},
}));

export const Affix = () => {
	const { classes } = useStyles();
	const { colors, radius, spacing } = useMantineTheme();

	const supportMenu = useDisclosure();
	const issuesModal = useDisclosure();
	const [scroll, scrollTo] = useWindowScroll();

	useHotkeys([['Ctrl+ArrowUp', () => scrollTo({ y: 0 })]]);

	return (
		<>
			<UIAffix position={{ bottom: '2rem', right: '2rem' }}>
				<Stack gap="xs">
					<Transition transition="slide-up" mounted={scroll.y > 400}>
						{(transitionStyles) => (
							<Tooltip label="Scroll to Top" position="left">
								<ActionIcon
									className={classes.elevation}
									size={48}
									color={colors.gray[9]}
									variant="white"
									radius={radius.xl}
									style={transitionStyles}
									onClick={() => scrollTo({ y: 0 })}
								>
									<Icon.IconChevronUp size="1.5rem" />
								</ActionIcon>
							</Tooltip>
						)}
					</Transition>
					<Tooltip label="Help and resources" position="left">
						<Menu
							opened={supportMenu.isOpen}
							onChange={supportMenu.toggle}
							position="top-end"
							offset={12}
							withArrow={false}
							width={270}
							radius={radius.round_12}
							classNames={{
								item: classes.menuItem,
								dropdown: classes.menuDropdown,
								itemSection: classes.menuItemSection,
							}}
							transitionProps={{
								transition: 'fade-up',
								duration: 150,
							}}
						>
							<Menu.Target>
								<ActionIcon
									className={classes.elevation}
									size={48}
									variant="gradient"
									gradient={{ from: colors.gray[7], to: colors.gray[9], deg: 180 }}
									radius="xl"
								>
									{supportMenu.isOpen ? (
										<Icon.IconX size="1.5rem" />
									) : (
										<Icon.IconInfoSquareRoundedFilled size="1.5rem" />
									)}
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									leftSection={<Icon.IconBook size="1.5rem" />}
									component="a"
									href="https://docs.trustform.io/guide"
									target="_blank"
								>
									User guide
								</Menu.Item>
								<Menu.Item
									leftSection={<Icon.IconExclamationCircle size="1.5rem" />}
									onClick={issuesModal.open}
								>
									Report an issue
								</Menu.Item>
								<Menu.Item
									leftSection={<Icon.IconMail size="1.5rem" />}
									component="a"
									href="mailto:support@trustform.io"
								>
									<Stack gap={spacing.space_2}>
										<span className={classes.menuItemCaption}>Contact support</span>
										<span>support@trustform.io</span>
									</Stack>
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Tooltip>
				</Stack>
			</UIAffix>
			<HaveIssuesModal
				size="400px"
				opened={issuesModal.isOpen}
				title="Report an issue"
				onClose={issuesModal.close}
			/>
		</>
	);
};
