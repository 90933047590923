import React from 'react';

import { Button, Icon, useDisclosure } from '@tf/ui';

import { AddTokenModal } from './AddTokenModal';

export const AddTokenButton: React.FC = () => {
	const addTokenModal = useDisclosure();

	return (
		<>
			<Button leftSection={<Icon.IconPlus size={18} stroke={2.25} />} onClick={addTokenModal.open}>
				Create token
			</Button>

			<AddTokenModal title="Create token" opened={addTokenModal.isOpen} onClose={addTokenModal.close} />
		</>
	);
};
