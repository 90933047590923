import { useState } from 'react';

import { useUser } from '@clerk/clerk-react';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';
import { Box, Button, createModal, Select, Textarea, TFNotifier, useModalControl } from '@tf/ui';

export const HaveIssuesModal = createModal(() => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [category, setCategory] = useState<BackendTypes.FeedbackIssueCategory>('GENERAL');
	const [description, setDescription] = useState('');
	const { onClose } = useModalControl();
	const { user, isSignedIn } = useUser();

	const sendFeedback = async (): Promise<void> => {
		if (!isSignedIn) {
			return;
		}
		try {
			setIsSubmitting(true);
			await BackendClient.getInstance().feedbackIssue({
				category,
				// Replace special characters as their presence break request to Linear
				description: `${description}  Sent from: ${window.location.href}, User agent: ${
					window.navigator.userAgent
				}, User id: ${user.id}, User email: ${
					user.emailAddresses[0]
				}, Time: ${new Date().toUTCString()}, App version: ${
					import.meta.env.VITE_APP_VERSION
				}`.replaceAll(/[\n\r\t]/g, ' '),
			});
			TFNotifier.info('Thank you for your feedback! We will get back to you soon.');

			setCategory('GENERAL');
			setDescription('');
		} catch (e) {
			TFNotifier.error('Something went wrong. Please try again later.');
		} finally {
			setIsSubmitting(false);
			onClose();
		}
	};

	return (
		<>
			<Select
				m="0.75rem"
				mt="1.25rem"
				mb="1.25rem"
				value={category}
				disabled={isSubmitting}
				data-readonly={false}
				comboboxProps={{ withinPortal: false }}
				onChange={(value) => setCategory((value as BackendTypes.FeedbackIssueCategory) || 'GENERAL')}
				data={[
					{ value: 'GENERAL', label: 'General issues' },
					{ value: 'MIGRATION', label: 'Migration issues' },
					{ value: 'OTHER', label: 'Other issues' },
				]}
				data-autofocus
			/>
			<Textarea
				m="0.75rem"
				mb="2rem"
				labelProps={{ size: 'xs' }}
				minRows={3}
				maxRows={10}
				value={description}
				onChange={(e) => setDescription(e.currentTarget.value)}
				disabled={isSubmitting}
				autosize
				placeholder="Please describe your issue"
			/>
			<Box m="0.75rem">
				<Button
					loading={isSubmitting}
					fullWidth
					size="sm"
					disabled={description.length < 3}
					loaderProps={{ size: 14 }}
					onClick={sendFeedback}
				>
					Submit
				</Button>
			</Box>
		</>
	);
});
