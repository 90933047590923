import { useSelector } from '@datagrid/state';

import { getAccountStructure } from '@tf/shared';

import { useAccountParams, useSelectedAccount, useSelectedAccountTasks } from '@/core/hooks';
import { appStore } from '@/core/stores';

export const useSelectedAccountStructureWithTasks = () => {
	const entities = useSelector(() => appStore.defs.get().entities);
	const account = useSelectedAccount();
	const { entityId } = useAccountParams();

	const accountTasks = useSelectedAccountTasks();
	const accountStructure = getAccountStructure({
		account,
		selectedEntityId: entityId,
		entitiesDefs: entities,
	});

	accountStructure.nodes.forEach((node) => {
		const entityId = node.data.graphId;

		node.data.tasks = accountTasks.flatMap((r) => {
			return r.entities.find((e) => e.entityId === entityId)?.tasks ?? [];
		});
	});

	return accountStructure;
};
